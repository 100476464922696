import * as React from "react";

function SvgQuotation(props) {
  return (
    <svg
      width={35}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.219 19.602c.573-2.37 1.51-5.339 2.812-8.907 1.328-3.593 2.683-6.862 4.063-9.804h8.554c-1.718 6.953-3.033 13.476-3.945 19.57H19.766l-.547-.86zm-18.242 0c.572-2.37 1.51-5.339 2.812-8.907C5.117 7.102 6.471 3.833 7.852.891h8.554c-1.718 6.953-3.034 13.476-3.945 19.57H1.523l-.546-.86z"
        fill="#919191"
      />
    </svg>
  );
}

export default SvgQuotation;
