import React from "react";
import styled from "styled-components";
import { breakpoint } from '../breakpoints/breakpoints';
import ImgTextSlice from "./imgtext-slice";
import Flex from "./flex";

const SectionWrapper = styled(Flex)`
background-color: ${props => props.bgColor}; 
padding: 30px 15px 70px 15px;
text-align: center;

@media ${breakpoint.mobileL} { 
padding: 60px 25px 80px 25px;
}

@media ${breakpoint.mobileXL} { 
padding: 60px 30px 99px 30px;
}
`
const H2 = styled.h2`
color: ${props => props.color};
`


function ImgTextSection (props) {
console.log(props);
    return (
        <SectionWrapper bgColor={props.bgColor} column justify="center" align="center" border={props.borderColor}>
            <H2 color={props.titleColor}>{props.sectionContent.title}</H2>
           
  {
      props.sectionContent.slice.map((slice, index) => {
          return(
              <ImgTextSlice key={index} content={slice} />
          )
      })
  }

        </SectionWrapper>
    )
}

export default ImgTextSection;
