import * as React from "react";

function SvgGreenCircle(props) {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill="#00B633" />
    </svg>
  );
}

export default SvgGreenCircle;
