import React from "react";
import styled from "styled-components";

import { breakpoint } from '../breakpoints/breakpoints';
import Flex from "./flex";
import Review from "./review";

const SectionWrapper = styled(Flex)`
background-color: ${props => props.bgColor};
padding: 80px 25px 16px 25px;

@media ${breakpoint.laptopXL} { 
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 55px 60px 85px 60px;
}
`

function TestimonialSection (props) {
   
    return(
        <SectionWrapper column bgColor={props.bgColor}>
            {
                props.sectionContent.content.map((review, index)=> {
                    return(
                        <Review 
                        key={index}
                        customer={review.customer}
                        city={review.city}
                        url={review.img}
                        text={review.text}
                        />
                    )
                })
            }
        </SectionWrapper>
        
    )
}

export default TestimonialSection;