import React from "react";
import styled from "styled-components";
import { breakpoint } from '../breakpoints/breakpoints';
import Flex from "./flex";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {RichText} from 'prismic-reactjs';

const SliceWrapper = styled(Flex)`
padding: 40px 0;
height: auto;

@media ${breakpoint.mobileXL} {
    flex-direction:  ${props => {
      if((props.placement === "right")) return "row-reverse";
      if((props.placement === "left" )) return "row";
      if((props.placement === "full")) return "column-reverse"; 
    }};
}
`

const ImgWrapper = styled(Flex)`
padding: 10px;

  .gatsby-image-wrapper {
    min-height: 200px;
    width: 60%;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
  }


  @media ${breakpoint.mobileM} {
    .gatsby-image-wrapper {
    min-height: 250px;
    width: 60%;
  }

@media ${breakpoint.mobileXL} {
    width: 60%;
    min-width: 240px;
    padding: 20px;
    .gatsby-image-wrapper {
    min-height: 200px;
    width: 200px;
  }
}

@media ${breakpoint.tablet} { 
    width: 40%;
    min-width: 290px;
    padding: 20px;
    .gatsby-image-wrapper {
    min-height: 250px;
    width: 250px;   
  }
}
  }
`

const TextWrapper = styled(Flex)`

@media ${breakpoint.mobileXL} {
    align-content: "center";
    max-width: ${(props)=>(props.placement === "full")?" 970px":" 680px"};
    width: ${(props)=>(props.placement === "full")?" 100%":" 60%"};
    ${(props)=>(props.placement === "full")?"margin:auto;":""}
    padding: 20px;
}
`

const P = styled.p`
text-align: left;
`
const FullGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  
  @media ${breakpoint.mobileM} {
    width: 100%;
    margin-bottom: 30px;
  }

  @media ${breakpoint.mobileXL} {
    width: 80%;
    margin-bottom: 30px;
  }

  @media ${breakpoint.tablet} { 
    width: 60%;
    margin-bottom: 0px;
  }

`



function ImgTextSlice (props) { 
        return (
            <SliceWrapper column placement={props.content.placement}>
                {props.content.placement !== 'full'? <ImgWrapper align="center" justify="center" placement={props.content.placement}>
                {/* <Img fluid={props.content.img} /> */}
                <GatsbyImage image={getImage(props.content.img)}  />
                </ImgWrapper>:<FullGatsbyImage image={getImage(props.content.img)}  />}

                <TextWrapper column align="flex-start" placement={props.content.placement}>
                <h3>{props.content.title}</h3>
                <P>{RichText.render(props.content.text)}</P>
                </TextWrapper>
                
                
            </SliceWrapper>
        )
    }
    
    export default ImgTextSlice;
  