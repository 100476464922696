import React from "react";
import styled from "styled-components";
import { breakpoint } from "../breakpoints/breakpoints";

import Flex from "./flex";
import CallToAction from "./call-to-action";
import ListDetail from "./list-detail";

const SectionWrapper = styled.div`
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.14;
    background-color: ${(props) => props.bgColor1};

    @media ${breakpoint.tablet} {
      background-image: linear-gradient(
        ${(props) => props.bgColor1} 382px,
        ${(props) => props.bgColor2} 382px
      );
    }
  }
`;

const SectionContent = styled(Flex)`
  z-index: 2;
  position: relative;
  padding: 40px 25px 80px 25px;

  @media ${breakpoint.tablet} {
    padding: 65px 28px 50px 28px;
  }
`;
const H2 = styled.h2`
  color: ${(props) => props.color};
`;
const FlexWrapper = styled(Flex)`
  margin-top: 40px;

  @media ${breakpoint.tablet} {
    flex-direction: row;
    margin-top: 55px;
  }
`;

const ShortList = styled.div`
  display: none;

  @media ${breakpoint.tablet} {
    display: block;
    column-count: 2;
    column-gap: 90px;
    width: 70%;
    max-width: 850px;
    padding-top: 50px;
  }
`;

const SeminarsWrapper = styled.div`
  text-align: center;
  max-width: 870px;
  margin-top: 40px;
`
const P = styled.p`
text-align: center;
font-size:25px;

@media ${breakpoint.tablet} {
  text-align: center;
  font-size:20px;
}
`

function BookingSection(props) {
  return (
    <SectionWrapper
      id="booking"
      bgColor1={props.bgColorColorful}
      bgColor2={props.bgColorLight}
    >
      <SectionContent column justify="center" align="center">
        <H2 color={props.titleColor}>{props.sectionContent.title}</H2>
        <P>{props.sectionContent.subTitle}</P>

        <FlexWrapper column>
          {props.sectionContent.content.map((cta, index) => {
            return (
              <CallToAction
                key={index}
                bgColor1={props.bgColorLight}
                bgColor2={props.bgColorDark}
                colorColorful={props.bgColorColorful}
                badgeColor={cta.badgeColor}
                badgeText={cta.badgeText}
                title={cta.description}
                price={cta.price}
                priceColor={cta.priceColor}
                keyphrase1={cta.keyphrase1}
                keyphrase2={cta.keyphrase2}
                keyphrase3={cta.keyphrase3}
                keyphrase4={cta.keyphrase4}
                btnText={cta.btnText}
                btnUrl={cta.btnUrl}
                btnColor={cta.btnColor}
                btnTextColor={cta.btnTextColor}
              />
            );
          })}
        </FlexWrapper>
        <SeminarsWrapper>
            <h2>{props.individualSeminarContent.content.title}</h2>
            <p>
            {props.individualSeminarContent.content.text}
            </p>
        </SeminarsWrapper>
        {props.shortDetails !== undefined && (
          <ShortList>
            {props.shortDetails.content.map((detail, index) => {
              return (
                <ListDetail
                  key={index}
                  title={detail.title}
                  text={detail.text}
                />
              );
            })}
          </ShortList>
        )}
      </SectionContent>
    </SectionWrapper>
  );
}

export default BookingSection;
