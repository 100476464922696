import * as React from "react";

function SvgPhone(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.758 1.01C4.17-.08 5.39.111 5.969 1.396 7.125 3.708 7.96 4.864 7.125 5.763 5.584 6.791 4.492 7.305 5.07 8.397c1.156 2.762 3.404 5.138 6.102 6.551 1.028.642 1.606-.45 2.762-1.863.963-.77 2.055.065 4.303 1.413 1.22.707 1.285 1.863.129 3.212C11.62 25.289-5.271 7.24 2.758 1.01z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPhone;
