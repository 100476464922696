import styled from 'styled-components';

const Badge = styled.span`
position: relative;
color: ${props => props.color};
font-weight: 500;
font-size: 14px;
font-style: normal;
line-height: 143%;
text-align: center;
letter-spacing: 0.4px;
text-transform: uppercase;
padding: 4px 16px 4px 16px;


&:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    z-index: 1;
    opacity: 0.14;
    background-color: ${props => props.color};
    }
`

export default Badge;