import React from "react";
import styled from "styled-components";
import { breakpoint } from "../breakpoints/breakpoints";

import Check2 from "../icons/Check2";
import Badge from "./badge";
import ButtonAngular from "./button-angular";
import Flex from "./flex";

const Wrapper = styled.div`
  width: 100%;
  min-width: 320px;
  max-width: 480px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-bottom: 37px;
  @media ${breakpoint.tablet} {
    margin-bottom: 0;
    margin: 0 7px;
  }
`;

const Header = styled.div`
  background-color: ${(props) => props.bgColor};
  padding: 40px 40px 24px 35px;
  @media ${breakpoint.tablet} {
    height: 216px;
  }
`;

const Body = styled.div`
  background-color: ${(props) => props.bgColor};
  padding: 40px 45px 24px 45px;
`;

const PriceDiv = styled.div`
  font-size: 24px;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Price = styled.span`
  color: ${(props) => props.color};
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;

  @media ${breakpoint.mobileM} {
    font-size: 60px;
  }
`;

const StyledCheck2 = styled(Check2)`
  margin-right: 17px;
  flex: 0 0 16px;
`;

const P = styled.p`
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 8px;
`;

function CallToAction(props) {
  return (
    <Wrapper>
      <Header bgColor={props.bgColor1}>
        {/* <Badge color={props.badgeColor}>{props.badgeText}</Badge> */}

        <PriceDiv>
          <Price color={props.priceColor}>{props.price}</Price>
        </PriceDiv>

        <h4>{props.title}</h4>
      </Header>

      <Body bgColor={props.bgColor2}>
        <Flex justify="flex-start" align="center">
          <StyledCheck2 />
          <P>{props.keyphrase1}</P>
        </Flex>

        <Flex justify="flex-start" align="center">
          <StyledCheck2 />
          <P>{props.keyphrase2}</P>
        </Flex>

        <Flex justify="flex-start" align="center">
          <StyledCheck2 />
          <P>{props.keyphrase3}</P>
        </Flex>

        <Flex justify="flex-start" align="center">
          <StyledCheck2 />
          <P>{props.keyphrase4}</P>
        </Flex>
{/* 
        <ButtonAngular
          href={props.btnUrl}
          bgColor={props.btnColor}
          textColor={props.btnTextColor}
          text={props.btnText}
        /> */}
      </Body>
    </Wrapper>
  );
}

export default CallToAction;
