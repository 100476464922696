import React from "react";
import styled from "styled-components";
import { breakpoint } from "../breakpoints/breakpoints";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import Flex from "./flex";

const AboutWrapper = styled(Flex)`
  background-color: ${(props) => props.bgColor};
  padding: 30px 15px 70px 15px;
  text-align: center;

  @media ${breakpoint.mobileL} {
    padding: 60px 25px 80px 25px;
  }

  @media ${breakpoint.mobileXL} {
    padding: 60px 30px 99px 30px;
  }
`;

const H2 = styled.h2`
  color: ${(props) => props.color};
`;

const Div = styled.div`
  position: relative;

  & div {
    z-index: 2;
    width: 344px;
    height: 194px;
    border: none;
    margin-top: 40px;

    @media ${breakpoint.mobileS} {
      width: 310px;
      height: 174.375px;
    }

    @media ${breakpoint.mobileXL} {
      width: 480px;
      height: 270px;
      border: 18px solid ${(props) => props.border};
    }

    @media ${breakpoint.tablet} {
      width: 618px;
      height: 348px;
      border: 18px solid ${(props) => props.border};
    }
  }
`;

const P = styled.p`
  text-align: center;
  max-width: 870px;
  font-size: 18px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 39px;
`;

const Bildmarke = styled.img`
  display: none;

  @media ${breakpoint.tablet} {
    display: inline-block;
    position: absolute;
    bottom: -100px;
    right: -82px;
    width: 164px;
    height: 164px;
    mix-blend-mode: multiply;
    z-index: 1;
  }
`;

function AboutWithMedia(props) {
  return (
    <AboutWrapper
      bgColor={props.bgColor}
      column
      justify="center"
      align="center"
      border={props.borderColor}
    >
      <H2 color={props.titleColor}>{props.sectionContent.title}</H2>
      <h3>{props.sectionContent.subtitle}</h3>
      <P>{props.sectionContent.content}</P>

      {/* <Div border={props.borderColor}>
        <LiteYouTubeEmbed
          id={props.sectionContent.youtubeId}
          title={props.sectionContent.alt}
          adNetwork={true} // Default true, to preconnect or not to doubleclick addresses called by YouTube iframe (the adnetwork from Google)
          playlist={false} // Use  true when your ID be from a playlist
          poster="hqdefault" // Defines the image size to call on first render as poster image. Possible values are "default","mqdefault",  "hqdefault", "sddefault" and "maxresdefault". Default value for this prop is "hqdefault". Please be aware that "sddefault" and "maxresdefault", high resolution images are not always avaialble for every video. See: https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
          noCookie={true} //Default false, connect to YouTube via the Privacy-Enhanced Mode using https://www.youtube-nocookie.com
        />
        {props.sectionContent.bildmarke !== undefined && (
          <Bildmarke src={props.sectionContent.bildmarke} alt="" />
        )}
      </Div> */}
      {/* <Description>{props.sectionContent.description}</Description> */}
    </AboutWrapper>
  );
}

export default AboutWithMedia;
