import React from "react";
import styled from "styled-components";
import GreenCircle from "../icons/GreenCircle";
import Check from "../icons/Check";

const Wrapper = styled.div`
display: inline-block;
position: relative;
width: 36px;
height: 36px;
margin-right: 20px;
flex: 0 0 36px;
`

const StyledCircle = styled(GreenCircle)`
position: absolute;
top: 8px;
width: 100%;
height: 100%;
`

const StyledCheck = styled(Check)`
position: absolute;
top: 18px;
left: 6px;
z-index: 1;
`

function Bulletpoint () {

    return (<div>
        <Wrapper>
        <StyledCircle />
        <StyledCheck />
        </Wrapper>
        </div>)
}

export default Bulletpoint;