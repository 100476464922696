import React from "react";
import styled from 'styled-components';
import { AnchorLink } from "gatsby-plugin-anchor-links";

const StyledAnchorLink = styled(AnchorLink)`
position: relative;
display: block;
min-width: 229px;
padding: 8px 20px;
border: none;
background-color: ${props => props.bgColor};
border: none;
border-radius: 20.5px;
text-align: center;
text-decoration: none;
color: ${props => props.textColor};
font-size: 18px;
font-weight: 600;
line-height: 25px;
cursor: pointer;
margin: 7px;

&:hover {
    color: ${props => props.textColor};  
    background-color: ${props => props.hoverColor};
}

&:visited {
    color: ${props => props.textColor};
}
`
function ButtonRound (props) {

    return (
    <StyledAnchorLink to={`/${props.page}#${props.section}`} title={props.title} bgColor={props.bgColor} textColor={props.textColor} hoverColor={props.hoverColor}>
      {props.text}
    </StyledAnchorLink>
    )};

export default ButtonRound;