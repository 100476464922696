import React from "react";
import styled from 'styled-components';

const StyledLink = styled.a`
position: relative;
display: block;
min-width: 229px;
padding: 8px 20px;
border: none;
background-color: ${props => props.bgColor};
border: none;
border-radius: 20.5px;
text-align: center;
text-decoration: none;
color: ${props => props.textColor};
font-size: 18px;
font-weight: 600;
line-height: 25px;
cursor: pointer;
margin: 7px;

&:hover {
    color: ${props => props.textColor};  
    background-color: ${props => props.hoverColor};
}

&:visited {
    color: ${props => props.textColor};
}
`
function ButtonLink (props) {

    return (
    <StyledLink href={`${props.href}`} title={props.title} bgColor={props.bgColor} textColor={props.textColor} hoverColor={props.hoverColor}>
      {props.text}
    </StyledLink>
    )};

export default ButtonLink;