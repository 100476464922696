import * as React from "react";

function SvgMail(props) {
  return (
    <svg
      width={21}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.925 10.548a.615.615 0 01-.849.003L0 1.031v13.054h21V.905l-10.075 9.643z"
        fill="#fff"
      />
      <path d="M20.07.086H.796l9.702 9.166L20.07.086z" fill="#fff" />
    </svg>
  );
}

export default SvgMail;
