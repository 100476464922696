import React from "react";
import styled from "styled-components";

const Div = styled.div`
-webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
`

const Title = styled.span`
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 185.5%;
padding-right: 10px;
`

const Text = styled.span`
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 185.5%;
`

function ListDetail (props) {



    return(
        <Div>
            <Title>{props.title}</Title>
            <Text>{props.text}</Text>
        </Div>

    )
}

export default ListDetail;